/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import MutationsStatus from '@app/components/molecules/MutationsStatus';

function AuthenticationModal() {
    const form = useRef();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);

    // New states for handling loading, success, and error
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const handleModalToggle = () => {
        setModalOpen(!isModalOpen);
    };

    const handleModalHide = () => {
        setModalOpen(false);
    };

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);

        const templateParams = {
            from_name: email,
            from_email: email,
            message,
        };

        emailjs
            .send('service_mv9cg2l', 'template_zx0oh6v', templateParams, 'r6mxJyg2u12V0dYk_')
            .then(
                (result) => {
                    console.log(result.text);
                    setLoading(false);
                    setSuccess(result);
                    setEmail(''); // Clear form inputs
                    setMessage('');
                    handleModalHide(); // Close the modal
                },
                (error) => {
                    console.log(error.text);
                    setLoading(false);
                    setError(error);
                }
            );
    };

    return (
        <>
            <button
                data-modal-target="authentication-modal"
                data-modal-toggle="authentication-modal"
                className="mt-2 font-lato text-base font-light leading-normal underline"
                type="button"
                onClick={handleModalToggle}
            >
                Contact support
            </button>

            {/* Main modal */}
            {isModalOpen && (
                <div
                    id="authentication-modal"
                    tabIndex="-1"
                    aria-hidden="true"
                    className="fixed top-0 left-0 right-0 z-10 w-full p-4 flex justify-center items-center overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
                >
                    <div className="relative w-full max-w-md max-h-full">
                        {/* Modal content */}
                        <div className="relative bg-white rounded-lg shadow bg-gray-100">
                            <button
                                type="button"
                                className="absolute top-3 right-2.5 text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-800 hover:text-white"
                                data-modal-hide="authentication-modal"
                                onClick={handleModalHide}
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="px-6 py-6 lg:px-8">
                                <h3 className="mb-4 text-xl font-medium text-gray-600">Send us a message</h3>

                                <form className="space-y-6" ref={form} onSubmit={sendEmail}>
                                    <div>
                                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="border text-sm rounded-lg block w-full p-2.5 bg-gray-100 border-gray-300 placeholder-gray-500 text-gray-700"
                                            placeholder="name@company.com"
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Message
                                        </label>
                                        <textarea
                                            id="message"
                                            rows="4"
                                            placeholder="Write your message here..."
                                            className="block p-2.5 w-full border text-sm rounded-lg block w-full p-2.5 bg-gray-100 border-gray-300 placeholder-gray-500 text-gray-700"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        className="mt-10 block w-full rounded-md bg-indigo-900 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Render MutationsStatus component */}
            <div className="fixed top-10 left-[95%] transform -translate-x-1/2 z-[10000]">
                <MutationsStatus 
                    loading={loading} 
                    error={error} 
                    success={success} 
                />
            </div>
        
        </>
    );
}

export default AuthenticationModal;
