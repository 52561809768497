import React from 'react';
import { PopupButton } from 'react-calendly';


function PopupComponent() {
    console.log(document.getElementById('landing-page'))
    return (
        <div>
        <PopupButton
                url="https://calendly.com/darko-bozhinoski/techvenia-call"
                rootElement={document.getElementById('__next')}
                text="Schedule a meeting"
                className="mt-2 font-lato text-base font-light leading-normal underline"
            />
        </div>
    );
};

export default PopupComponent;

